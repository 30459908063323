import { NgZone, Renderer, ElementRef, ViewChild, Component, ViewEncapsulation } from '@angular/core';
import { NgsRevealConfig } from 'ngx-scrollreveal';
import { RouterOutlet, Router,  Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { routerAnimation } from './common/animations';
import { SharedService } from './common/shared.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Title, Meta } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

declare let gtag;
@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  animations: [routerAnimation()],
  styleUrls: [
		'../assets/scss/style.scss'
  ],
  providers: [NgsRevealConfig] ,
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'Suramprady';
  router: string;
  spinnerElement: ElementRef
  isCollapsed = true;
  isAnimated:boolean=false;
  disabled = false;
  compact = true;
  invertX = false;
  invertY = false;

  shown: 'native' | 'hover' | 'always' = 'native';

  // menuCheck = document.querySelector('input')[0].checked;

  constructor(config: NgsRevealConfig, private _router: Router, private ngZone: NgZone,
    private renderer: Renderer, private spinner: NgxSpinnerService, private titleService: Title,
    private meta: Meta) {
    // customize default values of ngx-scrollreveal directives used by this component tree
    config.duration = 5000;
    config.easing = 'cubic-bezier(0.645, 0.045, 0.355, 1)';
    this.router = _router.url; 
    _router.events.subscribe(this._navigationInterceptor)
    const navEndEvents = _router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', 'UA-112829831-2', {
        'page_path': event.urlAfterRedirects
      });
    })
    //other options here
  }

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);
    this.titleService.setTitle(this.title);
    this.meta.addTags([
      {name: 'keywords', content: 'Interaction Designer, UX/UI, Service Designer, Interface Designer, Experience Designer, Milan Designer, Suramprady, Pradyumna Padmanabh Surampudi'},
      {name: 'description', content: 'Multidisciplinary Interaction Designer'},
      {name: 'author', content: 'Surampudi Pradyumna'},
      {name: 'robots', content: 'index, follow'},
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: '@suramprady' },
      { name: 'twitter:title', content: 'Multidisciplinary Interaction Designer' },
      { name: 'twitter:description', content: 'Learn frontend web development...' },
      { name: 'twitter:image', content: './assets/img/preview.png' },
      { name: 'og:title', content: 'Multidisciplinary Interaction Designer' },
      { name: 'og:type', content: 'website' },
      { name: 'og:url', content: 'suramprady.com' },
      { name: 'og:image', content: './assets/img/preview.jpg' },
      { name: 'og:site_name', content: 'Suramprady' },
    ])
  }
  public getRouteAnimation(outlet: RouterOutlet) {
    const res =
      outlet.activatedRouteData.num === undefined
        ? -1
        : outlet.activatedRouteData.num;

    return res;
  }
  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }
  // Shows and hides the loading spinner during RouterEvent changes
  private _navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      // We wanna run this function outside of Angular's zone to
      // bypass change detection
      this.ngZone.runOutsideAngular(() => {
        // For simplicity we are going to turn opacity on / off
        // you could add/remove a class for more advanced styling
        // and enter/leave animation of the spinner
        this.renderer.setElementStyle(
          this.spinnerElement.nativeElement,
          'opacity',
          '1'
        )
      })
    }
    if (event instanceof NavigationEnd) {
      this._hideSpinner()
    }
    // Set loading state to false in both of the below events to
    // hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this._hideSpinner()
    }
    if (event instanceof NavigationError) {
      this._hideSpinner()
    }
  }
  isChecked() {
    const ele = document.getElementById("toggle") as HTMLInputElement;
    ele.checked = false;
  }
  private _hideSpinner(): void {
    // We wanna run this function outside of Angular's zone to
    // bypass change detection,
    this.ngZone.runOutsideAngular(() => {
      // For simplicity we are going to turn opacity on / off
      // you could add/remove a class for more advanced styling
      // and enter/leave animation of the spinner
      this.renderer.setElementStyle(
        this.spinnerElement.nativeElement,
        'opacity',
        '0'
      )
    })
  }
}
