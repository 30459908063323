import { Routes } from '@angular/router';
import { OtherComponent } from './other/other.component';
import { ProjectsComponent } from './projects/projects.component';
import { OoxiComponent } from './ooxi/ooxi.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { MydaComponent } from './myda/myda.component';
import { ShoppyComponent } from './shoppy/shoppy.component';
import { PiComponent } from './pi/pi.component';
import { LaCittaBellaComponent } from './lacittabella/lacittabella.component';
import { KraanComponent } from './kraan/kraan.component';
import { InstaFlixComponent } from './instaflix/instaflix.component';

export const ROUTES: Routes = [
  { path: '',      component: HomeComponent, data: { num: 1 }},
  { path: 'home',  component: HomeComponent, data: { num: 1 } },
  { path: 'work-with-me', component: OtherComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'ooxi', component: OoxiComponent, data: { num: 3 } },
  { path: 'pi', component: PiComponent, data: { num: 3 } },
  { path: 'lacittabella', component: LaCittaBellaComponent, data: { num: 3 } },
  { path: 'myda', component: MydaComponent, data: { num: 3 } },
  { path: 'shoppy', component: ShoppyComponent, data: { num: 3 } },
  { path: 'kraan', component: KraanComponent, data: { num: 3 } },
  { path: 'instaflix', component: InstaFlixComponent, data: { num: 3 } },
  { path: 'about', component: AboutComponent, data: { num: 2 } },
//   { path: 'detail', loadChildren: './+detail#DetailModule'},
//   { path: 'barrel', loadChildren: './+barrel#BarrelModule'},
  { path: '**',    component: PagenotfoundComponent },
];
