import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  tabSel: 'All';
  constructor(private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle("Home | Suramprady");
  }
  eventTab(sel?: any) {
    let selected = sel !== undefined ? sel : this.tabSel;
    let prevSel = document.getElementsByClassName('events-tab-content active');
    let curSel = document.getElementById(selected);
    let prevTab = document.querySelector('.events-tab-list .active');
    let tabSel = document.getElementById(selected + '-tab');
    if (prevSel[0]) {
      prevSel[0].classList.remove('active');
      prevTab.classList.remove('active');
    }
    curSel.classList.add('active');
    tabSel.classList.add('active');
  }
}
