import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

import mediumZoom from 'medium-zoom'
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-ooxi',
  templateUrl: './ooxi.component.html',
  styleUrls: ['./ooxi.component.scss'],
  animations: [
    trigger('scrollAnimation', [
      state('show', style({
        opacity: 1,
        transform: "translateX(0)"
      })),
      state('hide',   style({
        opacity: 0,
        transform: "translateX(-100%)"
      })),
      transition('show => hide', animate('700ms ease-out')),
      transition('hide => show', animate('700ms ease-in'))
    ])
  ]
})
export class OoxiComponent implements OnInit {
  title = 'Ooxi';
  isCollapsed = true;
  cur = 'cover';
  state = 'hide'
  

  constructor(public el: ElementRef,private titleService: Title) { 
  }

  ngOnInit() {
    mediumZoom('[data-zoomable]', {margin: 0,  background: '#ffffffed' })
    this.titleService.setTitle("Ooxi | Suramprady");

  }
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const componentPosition = this.el.nativeElement.offsetTop
    const scrollPosition = window.pageYOffset
    console.log("scroll "+ scrollPosition);
    
    if (scrollPosition >= componentPosition) {
      this.state = 'show'
    } else {
      this.state = 'hide'
    }
  }

  onSectionChange(sectionId: string) {
    this.cur = sectionId;
  }

  scrollTo(section) {
    document.querySelector('#' + section)
    .scrollIntoView({ block: 'start',  behavior: 'smooth' });
  }
}