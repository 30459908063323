import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'desk-nav',
  template: `
  
  <div class="desk-nav">
    <div class="nav-brand">
      <a class="nav-item" [routerLinkActiveOptions]="{exact: true}" routerLink="/" routerLinkActive="active">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256.7 94.51">
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_3" data-name="Layer 3">
              <path class="cls-1"
                d="M154,12.67a97.5,97.5,0,0,1,0,23.47c-.68,6.9-1.93,14.39-.25,21.22.63,2.57,4.28,1.86,4.44-.6.49-8,.55-16.17.86-24.2.28-7.14,0-14.15-2.85-20.8-.58-1.35-2.33-.28-2.17.91Z" />
              <path class="cls-1"
                d="M140.86,21.33c.82-6.27,6.74-10,11.87-12.7,4.4-2.34,13.79-6.77,18.67-3.35,9.41,6.6-11.31,23.79-16.49,27.87-2,1.54.57,4.76,2.65,3.44,7.37-4.68,32.24-27.9,15.85-35.32-7.06-3.2-15.57.24-21.72,3.88s-12.31,8.48-13.05,16.18c-.14,1.44,2,1.39,2.22,0Z" />
              <path class="cls-1"
                d="M171.55,58.45l.14-.63a1.33,1.33,0,0,0-.77-1.57,1.31,1.31,0,0,0-1.65.54l-.28.58a1.4,1.4,0,1,0,2.56,1.08Z" />
              <path class="cls-1"
                d="M172.06,54.16c.37-3.12-.35-5.52-1.33-8.45-1.58-4.71,0-7.92,5.05-9.31,2.69-.75,9.11-2,10.63,1.53s-.11,9.06-.54,12.69c-.29,2.51,3.69,2.91,4.34.58,1.33-4.82,2.71-12.75-.63-17.11-2.8-3.66-7.81-3.24-11.86-2.64-4.54.66-9.93,2.46-11.53,7.32-2,6,4,9.83,4,15.39,0,1.21,1.7,1.14,1.83,0Z" />
              <path class="cls-1"
                d="M205.67,40.15c-4.53.12-8.57,1.9-10.93,5.92-1.83,3.11-2.24,7.87.74,10.46,3.42,3,7.87-.16,10.6-2.59s7.09-7.33,4.38-11.15c-1.4-2-4.94-1.46-4.86,1.32a17,17,0,0,0,3.11,9.43,2.23,2.23,0,0,0,3.84-2.25,21.76,21.76,0,0,1-1.73-7.18l-3.93,2.25q-.57-1-.66.42a16.55,16.55,0,0,1-1,1.49,16.87,16.87,0,0,1-2,2.23c-1.28,1.23-4.9,4.66-5.65,1.38-1-4.47,4.64-8.57,8.4-9.2,1.44-.24,1.09-2.57-.35-2.53Z" />
              <path class="cls-1"
                d="M223.54,38.42c-5,3.79-8.64,8.08-8.49,14.79.12,5.34,3.11,9.9,8.35,6,5.52-4.12,7.25-11.45,8.13-17.89a139.54,139.54,0,0,0,.87-30c-.28-3.36-5.19-3.45-5.3,0-.29,9.28-1.15,18.73-.63,28,.38,6.69,2,12.54,7.33,16.9a1.94,1.94,0,0,0,2.74-2.74c-4.39-4.86-4.72-12.24-4.65-18.43.09-7.91.34-15.83.51-23.74h-5.3c1,11.17,1.6,22.74-.86,33.78-.47,2.11-4.6,13.47-6.75,10.2-2.71-4.14,2.15-12.07,5.84-13.8,2.09-1,0-4.44-1.79-3.06Z" />
              <path class="cls-1"
                d="M241.12,43.5c1.4,2.93,2.82,5.86,4.2,8.8a1.67,1.67,0,0,0,2.59.34,32.48,32.48,0,0,0,8.54-10.45l-4.1-1.73c-2.46,13-4.41,26.83-9,39.3-1.16,3.14-3,5.63-4.42,8.59l.5,1.58a19.08,19.08,0,0,1-.29-3.54,19.83,19.83,0,0,1,.47-4.21A23.6,23.6,0,0,1,250,67.43a2.17,2.17,0,0,0-2.19-3.75,25.13,25.13,0,0,0-13.07,17.16c-.74,3.47-2.06,15.41,4.72,13.45,3-.86,4.86-5.05,6.13-7.54a60.13,60.13,0,0,0,4.47-12.64c2.67-10.66,5-21.59,6.6-32.45.34-2.26-3-4.27-4.1-1.73a37,37,0,0,1-7,10.38l2.59.33c-1.72-2.74-3.42-5.51-5.12-8.27a1.11,1.11,0,0,0-1.92,1.13Z" />
              <path class="cls-1"
                d="M41.72,10.39C43.08,2,28,10.4,25.2,11.77A129.9,129.9,0,0,0,10,20.52C7.16,22.4,2.56,25,3.21,29,4.44,36.86,17,37,22.74,37.63c2.8.32,8.42.53,5.65,4.52C27.05,44.09,23,45.81,21,46.82c-6,3-12.19,3.76-18.79,4.47a2.25,2.25,0,0,0,0,4.49c9,.87,18.89-2.8,26.26-7.84C33,44.85,38.93,37.94,31,34.76c-3.48-1.39-7.43-1.54-11.13-1.86S4.5,31.41,9.6,26c3.4-3.63,9.4-6.37,13.71-8.74C24.6,16.51,39,10.08,39,10c.06,1.47,2.45,2,2.71.37Z" />
              <path class="cls-1"
                d="M43,32.38a30.76,30.76,0,0,0-7,13.31c-.7,3.13-1.81,8.53.89,11.05,7.23,6.76,17.64-13.21,20.34-17l-4.78-2c-2.56,7.68-4.84,17.4,3.1,22.68,2.24,1.48,4.52-1.76,2.79-3.61-4.66-5-2.63-11.92-.84-17.68.92-3-3.17-4.35-4.78-2q-2.83,4.09-5.66,8.2c-1,1.49-2,3.06-3.12,4.5l-1.51,1.78c-1.88,2.21-2.74,2.38-2.6.5-1.13-2.26.13-7,.69-9.28a29.71,29.71,0,0,1,4-9.21c.59-.91-.8-2-1.52-1.18Z" />
              <path class="cls-1"
                d="M62.43,56.78c4.08-1.1,6.77-2.72,8.68-6.76a13,13,0,0,0,1.27-5.12c0-.94-.34-2.06-.29-3a1.83,1.83,0,0,1,.68-2.77A3.29,3.29,0,0,1,75.4,37.1c1.09-.64,7.06-3.4,7.46-2.64.9,1.7-2.84,6.86-3.51,8.5a17.67,17.67,0,0,0,.28,14.45c1.09,2.33,4.18.54,3.84-1.62a22.11,22.11,0,0,1,1.65-13.14c1.19-2.72,2.82-5.61,2.7-8.68-.17-4.36-3.7-5.35-7.36-4.1-3.13,1.07-6.21,2.59-9.24,3.93-1.93.85-3.69,1.86-4.2,4.07-.64,2.81,1.31,5.43,1.09,8.23-.26,3.4-2.94,7.53-6.2,8.81-1.13.45-.71,2.2.52,1.87Z" />
              <path class="cls-1"
                d="M99.2,42.58c-7.58-4.37-15.34,4.78-13.07,12.29a6.51,6.51,0,0,0,8.24,4.24c4.17-1.16,6.18-5.11,7.45-8.9H96.65a19.08,19.08,0,0,0,4.67,7.2c1.83,1.67,4.81-.45,3.5-2.69a36.75,36.75,0,0,1-3-5.94,2.71,2.71,0,0,0-5.17,0,11.13,11.13,0,0,1-2.58,4.47c-1.45,1.24-3,1.14-3.22-.93C90.48,48.68,94.14,44,98,45.4c1.67.59,2.68-2,1.19-2.82Z" />
              <path class="cls-1"
                d="M111.27,44.84c.19-.67,1.22,4,1.24,4.65a37.27,37.27,0,0,1-.28,6.51l5.53.74c.43-3.14.56-7.41,2.4-10.12,3.6-5.32,3.22,4.19,2.71,6.37-.69,2.92,3.76,5.17,5.09,2.15a66.3,66.3,0,0,1,2.9-6.15c1-1.68,2.8-5.58,4.6-6.28,4-1.55,3.81,3.15,4.54,5.65a47.5,47.5,0,0,0,2.15,6.14c1.23,2.79,5,.62,4.56-1.93a50.7,50.7,0,0,0-3.82-13.27c-1.19-2.46-3.32-4-6.14-3.62-6.88,1-12.08,11.57-13.88,17.31l5.37,1.48c.82-5.16,1.35-16.31-6.57-16.13-7,.17-9.47,12.37-9.44,17.66,0,3.62,5.63,3.63,5.63,0,0-3.94.92-11.68-2.78-14.49a3.94,3.94,0,0,0-6.37,2.62c-.25,1.65,2.1,2.4,2.56.71Z" />
            </g>
          </g>
        </svg>
      </a>
    </div>
    <nav class="nav">
      <a class="nav-item" [routerLinkActiveOptions]="{exact: true}" routerLink="/" routerLinkActive="active">Home</a>
      <a class="nav-item" [routerLinkActiveOptions]="{exact: true}" routerLink="/about" routerLinkActive="active">About</a>
      <a class="nav-item" [routerLinkActiveOptions]="{exact: true}" routerLink="/projects" routerLinkActive="active">Projects</a>
    </nav>
  </div>`
//   styleUrls: ['./other.component.scss']
})
export class DeskNavComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
