import { Component, OnInit } from '@angular/core';
import { SharedService } from './shared.service';

@Component({
  selector: 'app-spinner',
  template:`
            <div *ngIf=showSpinner class="spinner-loading">
                <div *ngIf=showSpinner class="spinner-loader"></div>
            </div>`,
  styles: ['../assets/scss/spinner.scss']
})
export class SpinnerComponent {
  showSpinner: boolean;

  constructor(private sharedService: SharedService) {
    this.sharedService.isSpinnerShown.subscribe(state => this.showSpinner = state);
  }
}
