import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { ROUTES } from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { OtherComponent } from './other/other.component';
import { ProjectsComponent } from './projects/projects.component';
import { OoxiComponent } from './ooxi/ooxi.component';

// import { NgScrollIndicatorModule } from 'ngx-text-highlight';
import { NgScrollIndicatorModule } from 'ngx-scroll-indicator';
import { SharedService } from './common/shared.service';
import { ProgressbarModule } from 'ngx-bootstrap';
import { SpinnerComponent } from './common/spinner.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ScrollSpyDirective } from './common/scrollspy.directive';
import { MydaComponent } from './myda/myda.component';
import { ShoppyComponent } from './shoppy/shoppy.component';
import { PiComponent } from './pi/pi.component';
import { LaCittaBellaComponent } from './lacittabella/lacittabella.component';
import { KraanComponent } from './kraan/kraan.component';
import { InstaFlixComponent } from './instaflix/instaflix.component';
import { DeskNavComponent } from './common/desknav.component';
import { NgScrollbarModule, NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    ScrollSpyDirective,
    HomeComponent,
    AboutComponent,
    PagenotfoundComponent,
    OtherComponent,
    ProjectsComponent,
    OoxiComponent,
    MydaComponent,
    ShoppyComponent,
    PiComponent,
    LaCittaBellaComponent,
    KraanComponent,
    InstaFlixComponent,
    DeskNavComponent
  ],
  imports: [
    BrowserModule,
    NgxSpinnerModule,
    NgScrollbarModule,
    BrowserAnimationsModule,
    NgScrollIndicatorModule.forRoot(),
    ProgressbarModule.forRoot(),
    RouterModule.forRoot(ROUTES, {
      useHash: Boolean(history.pushState) === false,
      preloadingStrategy: PreloadAllModules
    }),
  ],
  providers: [
    SharedService,
    { 
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        position: "native",
        visibility: "hover",
        appearance: "compact",
      }
   }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
