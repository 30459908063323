import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
@Injectable()
export class SharedService {
   titleAndBreadCrumb = new BehaviorSubject<string[]>([]);
   ShowSpinner = new BehaviorSubject<boolean>(false);

  // Title
  currentHeading = this.titleAndBreadCrumb.asObservable();
  // SpinnerComponent
  isSpinnerShown = this.ShowSpinner.asObservable();


  constructor() { }
  changeHeading(_title: string[]) {
    this.titleAndBreadCrumb.next(_title);
  }

  showSpinner(show: boolean) {
    this.ShowSpinner.next(show);
  }
}
